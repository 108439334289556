/*
 * This is an entrypoint file that will be compiled into interview.css.
 *
 */

/* Custom CSS files */
@import "../config/config_vidcruiter";
@import "../sources/base";
@import "../sources/module";
@import "../sources/state";
@import "../sources/old_stuff";
@import "../sources/util";
@import "../sources/icons";
@import "../sources/overrides";
@import "../sources/mediaelementplayer";
@import "../sources/components";
@import "../sources/vidcruiter_30";
@import "../sources/vendors/tinymce_overrides";
@import "../sources/vendors/froala_overrides";

/* Vendor CSS files */
@import "../sources/forms.css";
@import "../sources/jquery-ui-1.8.24.custom.css";
@import "../sources/jquery.meow.css";
@import "../sources/jquery.qtip2.css";
@import "../sources/prettyLoader.css";
@import "../sources/jquery.countdown.css";
@import "../sources/icheck/square/green.css";
@import "../sources/vendors/jquery/plugins/fancybox/jquery.fancybox.css";
@import "../sources/speed.css";
@import "../sources/jquery.bootstrap-touchspin.min.css";
@import "../sources/vendors/jquery/jquery.basictable.css";
@import "../sources/vendors/jquery/jquery-filestyle.css";
@import "../sources/vendors/jquery/jquery-confirm.css";
@import "../sources/vendors/pipe.css";
@import "../sources/fullcalendar.min.css";
@import "../sources/responsive_rating_system.css";
@import "../sources/vendors/intlTelInput/intlTelInput.css";
@import "../sources/vendors/tom-select/tom-select.css";
@import "../sources/webfonts.css";
@import "../sources/vendors/uppy.160.css";
@import "froala-editor/css/froala_editor.css";
@import "froala-editor/css/froala_style.css";
@import "froala-editor/css/plugins.pkgd.css";

/* Select2 Overrides */
.select2-container .select2-selection--single .select2-selection__clear {
  font-size: theme(fontSize.lg) !important;
}

.select2-search--dropdown .select2-search__field {
  font-size: theme(fontSize.sm);
  height: auto;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 27px !important;
}

.select2-container--open {
  z-index: 19999;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #034DC4 !important;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #034DC4 !important;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #034DC4 !important;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #034DC4 !important;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #034DC4 !important;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #034DC4 !important;
}

.fix-css-cache-interview {
  display: none;
}