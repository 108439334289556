@media (max-width : 768px) {
	.vidcruiter-custom-design .ct-dialog-content {
		width: 100%;
		border-radius: 0px 0px 0px 0px;
		box-shadow: none;
	}
	.vidcruiter-custom-design .ct-dialog-content .green-header {
		padding: 0px;
		border-radius: 0px 0px 0px 0px;
	}
	.vidcruiter-custom-design .ct-dialog-content .green-header h2 {
		text-align: center;
	}

	.vidcruiter-custom-design #rating-floating-bar-trigger {float: left; width: 100%;}
	.vidcruiter-custom-design #rating-floating-bar-container {position: relative; height: auto; margin-bottom: 0px;}
	.vidcruiter-custom-design #rating-floating-bar {width: 100%; z-index: 1001; position: initial;  border-top: 2px solid theme(colors.neutral.300);}

	#rating-float-bar {border-top: 1px solid theme(colors.neutral.300); border-bottom: 2px solid theme(colors.neutral.300); width: 100% !important;}
	#rating-float-bar-trigger {width: 100% !important;}

	#rating-float-bar .menu-progress {}
	#rating-float-bar .menu-progress-left {display: none;}
	#rating-float-bar .menu-progress-left .progress {width: 100%; margin-top: 0px; margin-bottom: 0px; height: 20px;}
	#rating-float-bar .menu-progress-left .progress div {text-align: left; font-size: theme(fontSize.2xs); line-height: 14px;}

  	#rating-float-bar .menu-current {margin-left: 0px;}
  	#rating-float-bar .menu-current span {font-weight: bold; color: theme(colors.neutral.800); font-size: theme(fontSize.base);}

  	#rating-float-bar .menu-description {}
  	#rating-float-bar .menu-description span {
			@apply ml-0 p-1 text-xs font-bold;
		}
	#rating-float-bar .menu-all {width: 100%;}
	#rating-float-bar .menu-all a {font-size: theme(fontSize.xs); margin-left: 0px; padding: 3px;}

	#rating-float-bar .menu-description .label-white-v3 {padding: 2px 2px 2px 2px; font-size: theme(fontSize.xs);}

	#vidcruiter-rating-global-progress {float: right; margin-left: 0px; padding-left: 0px; font-weight: bold; width: 100%; text-align: center; background-color: theme(colors.neutral.100); border-radius: 999px;}

	.vidcruiter-rating-current-index {width: 16px; text-align: center; font-weight: bold; left: -18px; top: 2px; font-size: 8px;}
	.vidcruiter-rating-current-index .number {font-size: theme(fontSize.2xs);}

	.ratings-person-avatar {width: 35px; height: 40px;}
	.ratings-person-avatar img {width: 40px; height: 40px; position: relative;}
	.ratings-panel-header-selected .ratings-person-avatar {width: 35px; height: 40px;}
	.ratings-panel-header-selected .ratings-person-avatar img {width: 40px; height: 40px; position: relative;}
	.vidcruiter-rating-avatar {width: 40px;}

	.ratings-panel-header-selected .ratings-person-name {font-size: theme(fontSize.base);}
	.ratings-person-time {font-size: theme(fontSize.xs);}

	.hide-phone {display: none;}
	.ratings-panel-menu-row .btn-huge { font-size: theme(fontSize.xs); padding: 8px 12px; }
	.vidcruiter-rating-panel-description {width: 100%;}

    #rating-review-list-people { width: 100%; }
	#rating-review-list-people .divider-v3-header { font-size: theme(fontSize.sm); line-height: 26px; }
	#rating-review-list-people .review-name { width: 60%; }
	#rating-review-list-people .review-name .avatar { display: none; }
	#rating-review-list-people .review-name .name p { font-size: theme(fontSize.sm); margin-top: 4px; }
	#rating-review-list-people .review-name .box-v3-more-identifier { font-size: theme(fontSize.xs); }
	#rating-review-list-people .box-v3-row { height: initial; }

	#rating-review-list-sidebar { display: none; }
	#rating-review-list-sidebar-mobile { padding: 15px; width: 230px; }

	.ui-dialog { width: 100% !important; }
	.new-note-form { margin-left: 0px; }
	.new-note-form .avatar { display: none; }
	.new-note-form textarea { width: 93%; font-size: theme(fontSize.xs); }
	#notes-list {width: 100%;}
	#notes-list .entry {margin-left: 0px;}
	#notes-list .entry .avatar {display: none;}
	#notes-list .entry .note {margin-left: 0px; width: 100%;}

	#rating-sidebar { display: none !important; }

	#rating-sidebar-menu-button, #rating-sidebar-menu-button-hide { display: none !important; }
	#rating-vidcruiter-sideout {display: block;}
	.vidcruiter-slideout {display:none;position:fixed;top:0;right:0;height:100%;z-index:999999;width:350px;overflow-x:none;overflow-y:auto;background:theme(colors.neutral.100);color:theme(colors.neutral.800);box-shadow:inset 0 0 5px 5px theme(colors.neutral.200); padding: 15px;}

	.vidcruiter-field table h3 { font-size: theme(fontSize.sm); line-height: 24px; }

	.vidcruiter-show-regular { display: none; }
	.vidcruiter-show-mobile { display: initial; }

    table.vidcruiter-show-regular { display: none; }
    table.vidcruiter-show-mobile { display: table; }
}

/* Tablets probably won't use this */
/*@media (max-width : 668px) {
	#rating-review-list-people .review-stars { width: 20%; }
	#rating-review-list-people .review-stars .stars-container { display: none; }

	.vidcruiter-field table h3 { font-size: theme(fontSize.base); line-height: 24px; }
}

@media (max-width : 560px) {
	.vidcruiter-field table h3 { font-size: theme(fontSize.sm); }
}

@media (max-width : 414px) {
	.vidcruiter-field table h3 { font-size: theme(fontSize.sm); }
	.vidcruiter-field table h3 { font-size: theme(fontSize.sm); }
}

@media (max-width : 320px) {
	.vidcruiter-field table { font-size: theme(fontSize.xs); }
	.vidcruiter-field table h3 { font-size: theme(fontSize.xs); }
}
*/